import React from 'react';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {classes} from './ContactAndAddressSummary.st.css';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {ContactModel} from '../../../domain/models/Contact.model';
import {AddressModel} from '../../../domain/models/Address.model';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getCountryName, getSubdivisionName} from '../../../domain/utils/localeDataset.util';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';
import {cleanAddress} from '../../../domain/utils/cleanAddress';
import {ExtendedFieldsView} from '../ExtendedFieldsView/ExtendedFieldsView';
import {CollapsibleSection} from '../CollapsibleSection/CollapsibleSection';
import {DataExtendedFieldView} from '@wix/wixstores-client-storefront-sdk';

export enum ContactAndAddressSummaryDataHook {
  root = 'ContactAndAddressSummary.root',
  title = 'ContactAndAddressSummary.title',
  fullName = 'ContactAndAddressSummary.fullName',
  email = 'ContactAndAddressSummary.email',
  phone = 'ContactAndAddressSummary.phone',
  company = 'ContactAndAddressSummary.company',
  addressRow = 'ContactAndAddressSummary.addressRow',
  customField = 'ContactAndAddressSummary.customField',
  collapsibleSection = 'ContactAndAddressSummary.collapsibleSection',
}

export const ContactAndAddressSummary = ({
  contact,
  address,
  email,
  customField,
  dataExtendedFields,
}: {
  contact?: ContactModel;
  address?: AddressModel;
  email?: string;
  customField?: CustomFieldModel;
  dataExtendedFields?: DataExtendedFieldView[];
}) => {
  const localeKeys = useLocaleKeys();
  const {t} = useTranslation();

  const getAddressText = () => {
    let country;
    const countryKey = getCountryName(address?.country);
    if (countryKey) {
      country = address?.countryFullname;
      country = t(countryKey);
    } else {
      country = address?.countryFullname;
    }
    const addressWithoutSubdivision = {
      addressLine1: address?.streetAddress?.name
        ? `${address?.streetAddress?.name} ${address?.streetAddress?.number ?? ''}`
        : address?.addressLine,
      addressLine2: address?.addressLine2,
      city: address?.city,
      zipCode: address?.postalCode,
      country,
    };
    const subdivisionKey = getSubdivisionName(address?.country, address?.subdivision);

    if (subdivisionKey) {
      const subdivision = t(subdivisionKey);
      return localeKeys.checkout.shipping_details.delivery_address2({
        ...addressWithoutSubdivision,
        subdivision,
      });
    } else {
      return localeKeys.checkout.shipping_details.delivery_address2_without_subdivision(addressWithoutSubdivision);
    }
  };

  const getFullName = () => {
    return `${contact?.firstName ?? /* istanbul ignore next */ ''} ${
      contact?.lastName ?? /* istanbul ignore next */ ''
    }`;
  };

  return (
    <div data-hook={ContactAndAddressSummaryDataHook.root} className={classes.root}>
      <Text className={classes.content} priority={TextPriority.secondary}>
        <div data-hook={ContactAndAddressSummaryDataHook.fullName}>{getFullName()}</div>
        <div data-hook={ContactAndAddressSummaryDataHook.company}>{contact?.company ?? ''}</div>
        {email && <div data-hook={ContactAndAddressSummaryDataHook.email}>{email}</div>}
        {address && <div data-hook={ContactAndAddressSummaryDataHook.addressRow}>{cleanAddress(getAddressText())}</div>}
        <div data-hook={ContactAndAddressSummaryDataHook.phone}>{contact?.phone ?? ''}</div>
        {customField && (
          <div data-hook={ContactAndAddressSummaryDataHook.customField}>
            {`${customField.title}: ${customField.value}`}
          </div>
        )}
        {!!dataExtendedFields?.length && (
          <CollapsibleSection dataHook={ContactAndAddressSummaryDataHook.collapsibleSection}>
            <ExtendedFieldsView values={dataExtendedFields} />
          </CollapsibleSection>
        )}
      </Text>
    </div>
  );
};
